.desktop-panel-drawer {
  background-color: white;
  width: 40vw;
  max-width: 700px;
  height: calc(100vh - 64px);
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 99;
  box-shadow: -14px 0px 14px rgba(0, 0, 0, 8%);
  padding: 30px;
}
.desktop-panel-drawer-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
}
.drawe-content-scroll{
  overflow-y: auto;
  width: 100%;

}
.panel-drawer-titles {
  flex-grow: 1;
}
.panel-drawer-title-name {
  font-size: 18px;
  font-weight: bold;
}
.panel-drawer-title-profesor {
  font-size: 16px;
}
.desktop-icon-user-quota {
  min-width: 15px;
  height: 15px;
}

.panel-drawer-description-title {
  display: flex;
  gap: 0.5em;
  border-bottom: 0.5px solid var(--border-color);
  padding-bottom: 12px;
}
.confirmed-subject-title{
  color: var(--accent-color);
  font-weight: bold;
  font-size: 14px;
}
.row-horarios {
  display: flex;
  gap: 1rem;
}
.panel-drawer-description-observaciones {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.panel-drawer-horarios-inner-col {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.panel-drawer-horarios-inner-col:first-child {
  flex-grow: 1;
  max-width: 280px;
}

.panel-drawer-h4-title {
  color: var(--text-muted);
  /* padding: 7px 0 5px 0; */
  font-size: 14px;
}
.panel-drawer-h3-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}
.desktop-panel-drawer-content p {
  color: var(--text-primary);
  line-height: normal;
}

.panel-drawer-content-rows {
  width: 100%;
  padding: 18px 0;
  border-bottom: 0.5px solid var(--border-color);
}
.panel-drawer-content-rows h4{
  color: var(--text-muted);
  font-size: 16px;
  margin-bottom: 12px;
  line-height: normal;
}

.row-modalidad {
  display: flex;
  gap: 1rem;
}
.modalidad-inner-col {
  width: 20%;
}

.panel-actions-row {
  display: flex;
  justify-content: space-between;
}

.panel-drawer-description-box {
}
.panel-drawer-description > h3 {
  font-size: 16px;
  margin-bottom: 0.5rem;
}
.panel-drawer-description-text {
  font-size: 12px;
}

.panel-drawer-description-actions {
  display: flex;
  justify-content: space-between;
}
.panel-drawer-circle-buttons {
  display: flex;
  gap: 0.5rem;
}
.panel-drawer-actions {
  border-radius: 100vh;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--accent-color);
  font-size: 15px;
  border: 2px solid var(--accent-color);
  background-color: white;
  cursor: pointer;
}
#row-actions {
  background-color: white;
  padding-top: 18px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.panel-drawer-actions.btn-calendar {
  background-color: var(--accent-color);
  color: white;
}

.panel-drawer-actions.btn-pin {
  background-color: var(--accent-color);
  color: white;
}

.panel-drawer-actions .btn-calendar:hover {
  background-color: var(--accent-color-darken);
  border-color: var(--accent-color-darken);
}

.panel-tags-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 5px;
}
.panel-tags-list-container p {
  font-size: 11px;
  padding: 2px 7px 0 7px;
  border-radius: 100vh;
  color: white;
}
.panel-drawer-link{
  color: var(--accent-color);
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}


/* SCROLL ------------------------*/
.drawe-content-scroll::-webkit-scrollbar {
  height: 12px;
  width: 5px;
}
.drawe-content-scroll::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #DFE9EB;
}

.drawe-content-scroll::-webkit-scrollbar-track:hover {
  background-color: #d4dbdd;
}

.drawe-content-scroll::-webkit-scrollbar-track:active {
  background-color: #d4dbdd;
}

.drawe-content-scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  /* background-color: #00c4b3; */
  background-color: #aaaaaa;
}

.drawe-content-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #2cc2b5;
}

.drawe-content-scroll::-webkit-scrollbar-thumb:active {
  background-color: #14a79a;
}

/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .desktop-panel-drawer{
    width: 100%!important;
    top: 0;
  }
  #panel-drawer.desktop-panel-drawer .desktop-panel-drawer-content p{
font-size: 14px;
  }
}
.row-ph {
  display: flex;
  gap: 1rem;
}
.content-rows-ph {
  width: 100%;
  /* border-top: 0.5px solid var(--border-color); */
  /* padding-top: 5px; */
}

.border-top-ph {
  border-top: 0.5px solid var(--border-color);
  padding-top: 10px;
}
/* ads */
.ph-horarios-inner-col {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.col-profesores-list{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.col-horarios > *:not(:first-child) {
  margin-top: 12px;
}

.ph-h4-title {
  color: var(--text-muted);
  /* padding: 7px 0 5px 0; */
  font-size: 14px;
  margin-bottom: 1px;
}
.content-rows-ph p{
  font-size: 14px;
}
.ph-professor-rol-pill {
  color: var(--accent-color);
  font-size: 14px;
  padding: 2px 7px;
  border-radius: 50px;
  border: 1px solid var(--accent-color);
  margin-left: 0.2rem;
  display: inline-block;
}
.profesores-first-col{
  width: 200px;
}

#horarios-in-panel-drawer{
  display: flex;
  border-bottom: 0.5px solid var(--border-color);
  padding: 18px 0;
}
.col-horarios{
  width: 55%;
}
.col-profesores{
  width: 50%;
}
.col-profesores>div.col-profesores-drawer{
  min-width: 100%;
}
#horarios-in-panel-drawer p{
  font-size: 16px;
}
#horarios-in-panel-drawer h4{
  font-size: 16px;
  color: var(--text-muted);
  line-height: normal;
  margin-bottom: 12px;
}



/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .content-rows-ph.mobile-horarios {
    flex-direction: column;
    gap: .5rem;
  }
  .ph-horarios-inner-col{
    display: flex;
    flex-direction: row;
  }
  .ph-horarios-inner-col.ph-horarios-inner-col-mobile{
    display: flex;
    flex-direction: column;
  }
  .ph-horarios-inner-col .ph-h4-title{
    width: 30%;
  }
  .ph-horarios-inner-col .horarios-col-mobile{
    width: 60%;
  }
  .ph-horarios-inner-col.hide-profesores-mobile{
  display: none;
}
.col-horarios{
  width: 75%;
}
}